.alertText{
    text-align: center;
}

.myButton{
    margin: 15px 10px;
}

.inputInfo:focus{
    box-shadow: none !important;
    border-color: black !important;
}

.accauntName{
    color: white;
    margin: 0;
    background: none;
    border: none;
}

.accauntName:hover{
    color: rgba(255,255,255, 0.8);
}