.alert{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
}
