
$primary: #084298;
$info: #00c40c;
//#37aa85
$secondary: #cfe2ff;
$warning: #e3e3e3;
//$card-border-color: #b6d4fe;
.btn_white{
    color: #ffffff !important;
}





@import "~bootstrap/scss/bootstrap";

