.search_and_ask{
    display: flex;
    justify-content: space-between;
    /*align-items: center;*/
    margin: 15px;
}

body .about{
    margin: 30px 15px 15px 15px;
    border-radius: 15px !important;
    background-color: rgba(0, 196, 12, 0.3) !important;

}

body .about_all{
    margin: 30px 15px 30px 15px;
    border-radius: 15px !important;
    background-color: rgba(0, 196, 12, 0.3) !important;

}

.about_ask{
    display: flex;
    align-items: center;
    justify-content: center;

}

.about_ask_text{
    text-align: center;
}

.about_tittle{
    font-size: 36px !important;
    text-align: center;
}

