.header{
    height: 80px;
    display: flex;
    justify-content: end;
    align-items: center;
    background: #212529;
}

.dropdown{
    display: flex;
    align-items: center;
}

.nav{
    display: flex;
    align-items: center;
}