.answer_done {
    border: solid;
    border-radius: 15px;
    background-color: #cfe2ff;
    border-width: 1px;
    border-color: #b6d4fe;
    /*min-height: 300px;*/
    margin: 15px;
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*position: relative;*/
}


.answer {
    /*width: 100%;*/
    /*max-width: 1200px;*/
    border: solid;
    border-radius: 15px;
    background-color: #f1f1f1;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.1);
    /*min-height: 300px;*/
    margin: 15px;
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*position: relative;*/
}

.ans_and_quest{
    width: 100%;
}

.make_answer {
    /*bottom: 0;*/
    display: flex;
    justify-content: end;
    /*position: absolute;*/
}

.make_answer_btn {
    margin: 15px;
    max-height: 67.6px;

}

.question_text{
    /*height: calc((100% - 67px )* 0.5);*/
    min-height: 50px;
    margin: 15px;
    width: auto;
    /*color: white;*/
}

.answer_text{
    /*height: calc((100% - 67px )* 0.5);*/
    min-height: 50px;
    margin: 15px;
    width: auto;
    /*color: white;*/
}

.without_btn_answer{
    display: flex;
    justify-content: end;
}

.hide_and_edit{
    display: flex;
}

.btn_hide{
    margin: 15px;
    padding: 2px 3px !important;
}
.btn_edit{
    margin: 15px 0 15px 15px;
    padding: 2px 3px !important;
}



.inputInfo:focus {
    box-shadow: none !important;
    border-color: black !important;
}

.ask {
    display: flex;
    justify-content: end;
}

.ask_btn {
    margin: 15px
}

.alertText{
    text-align: center;
}


/*.form_switch_input{*/
/*    margin-top: 5.5px;*/
/*}*/

/*.form_switch_input:focus {*/
/*    box-shadow: none;*/
/*    border-color: black;*/
/*}*/

/*.form_switch_input:checked {*/
/*    background-color: black;*/
/*    border-color: black;*/
/*}*/