.wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 1);
    z-index: 1070;
}

.window{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1071;
}


.spinner{
    width: 100px;
    height: 100px;
}