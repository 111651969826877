//.search_input{
//    width: 60%;
//    /*height: 37.6px;*/
//}

.search_btn{
    margin: 0 15px;
}

.search_input{
    margin: 0 15px ;
}

.search_input:focus {
    box-shadow: none !important;
    border-color: black !important;
}

.search{
    width: 100%;
    display: flex;
    //flex-direction: column;
    //align-items: center;
    margin: 15px 0px;
}

//.search_input_and_btn{
//    flex-direction: column;
//    align-items: center;
//    display: flex;
//    flex-direction: row;
//}
//
//.search_about{
//    margin: 15px 15px 0 0 ;
//}

.search_label{
    margin: 15px;
}