.inputInfo:focus {
    box-shadow: none !important;
    border-color: black !important;
}

.ask {
    display: flex;
    justify-content: center;

}

.ask_btn {
    margin: 15px 0px;
    width: 50%;
    /*height: 100px;*/
}

.alertText{
    text-align: center;
}

.btn{
    color: #ffffff !important;
}

/*.form_switch > input  {*/
/*    margin-top: 6px;*/
/*}*/

/*.form_switch > input:focus {*/
/*    box-shadow: none;*/
/*    border-color: black;*/
/*}*/

/*.form_switch > input:checked {*/
/*    background-color: black;*/
/*    border-color: black;*/
/*    }*/

.form_switch_input{
    margin-top: 5.5px;
}

.form_switch_input:focus {
    box-shadow: none !important;
    border-color: black !important;
}

.form_switch_input:checked {
    background-color: black !important;
    border-color: black !important;
}