.alert{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
}

.info{
    display: flex;
    height: 600px;
    align-items: center;
    justify-content: center;
}

.info_card{
  border-color: #b6d4fe;
  //padding: 15px;
}

.about_and_search_item{
  width: 100%;
  max-width: 1200px;
}

.about_and_search{
  display: flex;
  flex-direction: column;
  align-items: center;
}